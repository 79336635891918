import axios from 'axios'
import config from '@/config/config'

const client = axios.create({
  baseURL: config.palInfoApi.baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  //withCredentials: true,
  responseType: 'json',
})

export default {
  getList: (path = '', params = {}) => {
    return new Promise((resolve, reject) => {
      let options = {}
      let params_cloned = { ...params }
      options.params = params_cloned
      client.get(path, options)
        .then((res) => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
