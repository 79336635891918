import * as types from './mutation-types'

export default {
  setLoading: (ctx, isLoading) => {
    ctx.commit(types.SET_COMMON_LOADING, isLoading)
  },

  setCategories: async (ctx, payload) => {
    ctx.commit(types.SET_CATEGORIES, payload)
  },

  setPosts: async (ctx, payload) => {
    ctx.commit(types.SET_POSTS, payload)
  },

  resetPosts: async (ctx) => {
    ctx.commit(types.RESET_POSTS)
  },
}

