<template>
<nav class="navbar" role="navigation">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#top-navbar-1">
        <span class="sr-only">トグルナビ</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" href="/">パルシステム群馬</a>
    </div>
    <!-- ナビゲーション -->
    <div class="collapse navbar-collapse" id="top-navbar-1">
      <ul class="nav navbar-nav navbar-right">
        <li class="dropdown">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-delay="1000">
            <i class="fa fa-check-square"></i><br>パルシステム群馬とは <span class="caret"></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-left" role="menu">
            <li><a href="/pages/?id=about">理念・ビジョン</a></li>
            <li><a href="/pages/?id=about-profile">組織案内</a></li>
            <li><a href="/pages/?id=about-area">配送エリア</a></li>
            <li><a href="/pages/?id=privacy">個人情報保護方針</a></li>
            <li><a href="/pages/?id=environment-policy">環境方針</a></li>
            <li><a href="/posts/?id=200727">公告</a></li>
            <li><a href="https://www.pal.or.jp/guideline/" target="_blank">ソーシャルメディアご利用ガイドライン <i class="fa fa-external-link"></i></a></li>
            <li><a href="https://www.pal-system.co.jp/service/weekly-delivery/" target="_blank">パルシステムについて <i class="fa fa-external-link"></i></a></li>
          </ul>
        </li>
        <li>
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-delay="1000">
                          <i class="fa fa-users"></i><br>組合員活動<span class="caret"></span>
                        </a>
          <ul class="dropdown-menu dropdown-menu-left" role="menu">
            <li><a href="/pages/?id=activity">組合員活動とは・活動紹介</a></li>
            <li><a href="/posts/?category=entry">参加募集</a></li>
            <li><a href="/posts/?category=report">活動レポート</a></li>
          </ul>
        </li>
        <li>
          <a href="/posts/?category=info-all"><i class="fa fa-info-circle"></i><br>お知らせ</a>
        </li>
        <li class="navbar_login">
          <a href="http://www.pal-system.co.jp/" target="_blank"><i class="fa fa-shopping-cart"></i><br>ネット注文</a>
        </li>
        <li class="navbar_info">
          <a href="/document/" target="_blank"><i class="fa fa-file"></i><br>加入案内</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>
<script>
export default {
  name: 'HeaderNavbar',

  components: {
  },

  data(){
    return {
    }
  },
}
</script>
<style>
</style>

