var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm.isTopPage
          ? _c(
              "div",
              {
                staticClass: "col-sm-5 footer-other",
                attrs: { "data-start-pos": "150" },
              },
              [_vm._m(1), _vm._v(" "), _vm._m(2), _vm._v(" "), _vm._m(3)]
            )
          : _c("div", { staticClass: "col-sm-5 footer-other" }, [_vm._m(4)]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "col-sm-7 footer-copyright",
        attrs: { "data-start-pos": "150" },
      },
      [
        _c("p", [
          _vm._v(
            "Copyright © 2015 生活協同組合パルシステム群馬 All Rights Reserved. "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "https://twitter.com/palsystem_gunma",
          target: "_blank",
        },
      },
      [_c("i", { staticClass: "fa-brands fa-square-x-twitter fa-2x" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "https://www.instagram.com/palsystemgunma.ig/",
          target: "_blank",
        },
      },
      [_c("i", { staticClass: "fa-brands fa-instagram fa-2x" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "https://www.youtube.com/channel/UCx3t7wZkIOK7Y6bzX9ef3tQ",
          target: "_blank",
        },
      },
      [_c("i", { staticClass: "fa-brands fa-square-youtube fa-2x" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "/pages/?id=privacy" } }, [
      _c("i", { staticClass: "fa fa-lock" }, [_vm._v(" 個人情報保護方針")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }