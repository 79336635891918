var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("figure", { staticClass: "image", class: _vm.sizeClass }, [
    _c("img", {
      class: { "u-clickable": _vm.isClickable },
      attrs: { src: _vm.imageUrl },
      on: { error: _vm.noImage, click: _vm.activate },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }