<template>
  <div class="fullview">
    <div class="loading-spacer"></div>
    <vue-loading
      type="spin"
      color="#aaa"
      :size="{ width: '100px', height: '100px' }"
    >
    </vue-loading>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
  name: 'loading',

  components: {
    VueLoading,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style>
.fullview {
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.loading-spacer {
  height: calc(50% - 50px);
}
</style>

