import Vue from 'vue'

import mixin from '@/mixin'
Vue.mixin(mixin)

import * as filters from '@/filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

export default Vue
