import common from './common'

const functions = {
  getOneFromObjList: (objs, targetKey, targetVal) => {
    if (!objs || Array.isArray(objs) === false) return
    return objs.find(item => item[targetKey] === targetVal)
  },

  getValsFromNestedObjList: (objs, targetKey, nestKey = 'children') => {
    let ret = []
    for (let i = 0, n = objs.length; i < n; i++) {
      let obj = objs[i]
      if (common.checkObjHasProp(obj, targetKey)) {
        ret.push(obj[targetKey])
      }
      if (common.checkObjHasProp(obj, nestKey) && obj[nestKey]) {
        const items = functions.getValsFromNestedObjList(obj[nestKey], targetKey, nestKey)
        if (items) {
          items.map((item) => {
            ret.push(item)
          })
        }
      }
    }
    return ret
  },
}

export default functions

