import config from '@/config/config'
import util from '@/util'
import moment from '@/moment'

export default {
  data() {
    return {
      pageName: '',
    }
  },

  computed: {
    isLoading: function () {
      return this.$store.state.common.isLoading
    },

    serviceId() {
      return config.post.serviceId
    },

    config() {
      if (config.pages[this.pageName] == null) return ''
      return config.pages[this.pageName]
    },

    categories() {
      return this.$store.state.categories
    },

    categoriesNotNested() {
      if (this.checkEmpty(this.categories)) return []
      let ret = []
      this.categories.map((pitem) => {
        ret.push({ slug: pitem.slug, label: pitem.label })
        if (pitem.children) {
          pitem.children.map((item) => {
            ret.push({ slug: item.slug, label: item.label })
          })
        }
      })
      return ret
    },

    infoCategorySlugs() {
      const cate = util.arr.getOneFromObjList(this.categories, 'slug', 'info-all')
      if (!cate || this.checkObjHasProp(cate, 'children') === false) return ['info-all']

      const slugs = util.arr.getValsFromNestedObjList(cate.children, 'slug')
      slugs.push('info-all')
      return slugs
    },

    activityCategorySlugs() {
      const cate = util.arr.getOneFromObjList(this.categories, 'slug', 'activity')
      if (!cate || this.checkObjHasProp(cate, 'children') === false) return ['activity']

      const slugs = util.arr.getValsFromNestedObjList(cate.children, 'slug')
      slugs.push('activity')
      return slugs
    },
  },

  methods: {
    checkEmpty: util.common.isEmpty,
    checkObjHasProp: util.common.checkObjHasProp,

    setLoading(isLoading) {
      this.isLoading = isLoading
    },

    postDetailUrl(slug) {
      return `/posts/?id=${slug}`
    },

    mediaUrl(type, fileId, mimeType, size = 'raw') {
      const ext = util.media.getExtensionByMimetype(mimeType)
      let pathItems = [config.media.url, this.serviceId]
      if (type === 'image') {
        const fileName = `${size}.${ext}`
        pathItems.push('images', fileId, fileName)
      } else {
        const fileName = `${fileId}.${ext}`
        pathItems.push('docs', fileName)
      }
      return pathItems.join('/')
    },

    getCategoryLabel(cateSlug) {
      if (this.checkEmpty(this.categoriesNotNested)) return ''
      const res = this.categoriesNotNested.find((item) => item.slug === cateSlug)
      if (res == null) return ''
      return res.label
    },

    substr(text, len) {
      return util.str.substr(text, len, '...')
    },

    numFormat(num) {
      return util.str.numFormat(num)
    },

    dateFormat(date, format = 'LLL') {
      return moment(date).format(format)
    },
  },
}
