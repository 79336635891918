<template>
<footer>
  <div class="container">
    <div class="row">
      <div class="col-sm-7 footer-copyright" data-start-pos="150">
        <p>Copyright &copy; 2015 生活協同組合パルシステム群馬 All Rights Reserved. </p>
      </div>
      <div
        v-if="isTopPage"
        class="col-sm-5 footer-other"
        data-start-pos="150"
      >
        <a href="https://twitter.com/palsystem_gunma" target="_blank">
          <i class="fa-brands fa-square-x-twitter fa-2x"></i>
        </a>
        <a href="https://www.instagram.com/palsystemgunma.ig/" target="_blank">
          <i class="fa-brands fa-instagram fa-2x"></i>
        </a>
        <a href="https://www.youtube.com/channel/UCx3t7wZkIOK7Y6bzX9ef3tQ" target="_blank">
          <i class="fa-brands fa-square-youtube fa-2x"></i>
        </a>
      </div>
      <div
        v-else
        class="col-sm-5 footer-other"
      >
        <a href="/pages/?id=privacy"><i class="fa fa-lock"> 個人情報保護方針</i></a>
      </div>
    </div>
  </div>
</footer>
</template>
<script>
export default {
  name: 'CommonFooter',

  components: {
  },

  props: {
    isTopPage: {
      type: Boolean,
      default: false,
    },
  },

  data(){
    return {
    }
  },
}
</script>
<style>
</style>

