import * as types from './mutation-types'

export default {
  [types.SET_COMMON_LOADING] (state, isLoading) {
    state.common.isLoading = isLoading
  },

  [types.SET_CATEGORIES] (state, payload) {
    state.categories = payload
  },

  [types.SET_POSTS] (state, payload) {
    state.posts.pagerKey = payload.pagerKey
    payload.items.map(item => {
      if (payload.isLatest) {
        state.posts.items.unshift(item)
      } else {
        state.posts.items.push(item)
      }
    })
  },

  [types.RESET_POSTS] (state) {
    state.posts.items = []
    state.posts.pagerKey = null
  },
}

